.app {
  /* display: flex; */
}

.SideBar {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 3;
}

.App-content {
  margin: 0px 10px 0px 100px;

}




body {
  background-color: rgb(247, 248, 249);
}

:root {
  --color-primary: rgb(225, 240, 218);
  /* Main green color */

  --color-secondary: rgb(191, 216, 175);
  /* Main green color */


  --color-text: #333333;
  /* Dark text color */

  --color-link: #2196F3;
  /* Blue link color */
  --blue: #065ba0;

  --warning-color: rgb(225, 132, 132);


  /* colors */

  --background: #EDF7F6;

  --blue_01: #164863;
  --blue_02: #427D9D;
  --blue_03: #9BBEC8;
  --blue_04: #DDF2FD;

  --green_01: ;
  --green_02: primary
  ;
  --green_03: #00ADEF;
  --green_04: #E1F0DA;

  --black: #1d1d1f;
  --white: #ffffff;
  --warning: #db162f;
  --succesful: #1ec91c;

  --inactiveColor: #828887;

}

ul {
  padding: 0;
  margin: 0;
}

li {
  padding: 0;
  margin: 0;
}


.Title {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.sub_Title {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.label {
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.warning {
  font-size: 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: red;
}


.line {
  border-bottom: 2px solid rgba(0, 0, 0, 0.383);
  margin: 10px 0px;
}

.react-datepicker__day--highlighted {
  background-color: #252C58 !important; /* Bootstrap blue for example */
  color: white !important;
}

.Container {

  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px 20px;
  background-color: white;
  margin-top: 10px;
  width: 100%;
}



.form-input {
  height: 35px;
  width: 350px;
  background-color: var(--color-primary);
  border: 2px solid rgb(141, 141, 141);
  border-radius: 4px;
  padding-left: 5px;
}

.form-input-select {
  height: 34px;
  width: 350px;
  background-color: var(--color-primary);
  border: 2px solid rgb(141, 141, 141);
  border-radius: 4px;
  padding-left: 5px;

  &:focus {
    outline: 0px solid #ddd;
  }



}

.btn {
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  margin: 5px;
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
}

.btn-cancel {
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  margin: 5px;
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  background-color: transparent;
  color: rgb(170, 40, 40);
  border: 1px solid rgb(170, 40, 40);

  transition: .2s;

  &:hover {
    background-color: white;
    color: rgb(255, 255, 255);
    background-color: rgb(170, 40, 40);
    border: 1px solid rgb(255, 255, 255);
    transition: .2s;
  }
}

/* submit or update */
.btn-submit {
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  margin: 5px;
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  background-color: var(--color-secondary);
  color: rgb(0, 0, 0);
  border: 1px solid black;
  transition: .2s;

  &:hover {
    background-color: white;
    color: var(--color-secondary);
    border: 1px solid var(--color-secondary);
    transition: .2s;
  }
}

.btn-delete {
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  margin: 5px;
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  background-color: red;
  color: rgb(0, 0, 0);
  border: 1px solid black;
  transition: .2s;

  &:hover {
    background-color: white;
    color: red;
    border: 1px solid red;
    transition: .2s;
  }
}

.hide {
  display: none;
  background-color: #2196F3;
}



/* for search and select */
.select-sub {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.drop-down-arrow-expand {
  width: 15px;
  height: 15px;
  transition: .5s;
  margin: 10px;
}

.drop-down-arrow-close {
  width: 15px;
  height: 15px;
  rotate: 180deg;
  transition: .5s;
  margin: 10px;
}

#myInput {
  font-size: 16px;
  border: none;
  height: 22px;
  width: 270px;
  background-color: transparent;
}

#myInput:focus {
  outline: 0px solid #ddd;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1
}

.show {
  display: flex;
  flex-direction: column;
}






.search-select-div {


  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.Release-search-div-select {
  width: max-content;

}

.drop-down-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  border: 1px solid rgb(106, 106, 106);
  height: 30px;
  width: 500px;
  border-radius: 2px;
}

.drop-down-btn:hover,
.drop-down-btn:focus {
  background-color: rgba(54, 54, 54, 0.249);
}

.Release-search-div-select-input {
  font-size: 16px;
  border: none;
  height: 22px;
  width: 100%;
  background-color: transparent;

  &:focus {
    outline: 0px solid #ddd;
  }
}

.select-dropdown-img-on {
  width: 15px;
  height: 15px;
  transition: 0.3s;
}

.select-dropdown-img-off {
  width: 15px;
  height: 15px;
  transform: rotate(180deg);
  transition: 0.3s;
}

.dropdown-content-show {
  position: absolute;
  background-color: #f6f6f6;
  width: 498px;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-content-hide {
  display: none;

}

.dropdown-select-btn {
  background-color: rgba(15, 14, 14, 0.064);
  border: none;
  height: 25px;
  margin: 1px 0px;
  width: 100%;
}



table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

thead tr {
  background-color: var(--color-secondary);
  font-weight: 600;
}

/* table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
  
} */
table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}


@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
    background-color: #27252548;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}