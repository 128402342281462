.side_menu_systems {
    margin-bottom: 10px;
    /* border-left: 5px solid green; */
}

.side_menu_systems-system li {
    /* list-style: none; */
}

.side_menu_systems-system li a {
    text-decoration: none;
    /* background-color: rgb(214, 63, 63); */
}

.side_menu_systems-system-details {
    width: 410px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--inactiveColor);
    /* background-color: rgb(163, 163, 197); */
    /* border-left: 5px solid green; */
}

.side_menu_systems-system-details:hover {
    width: 410px;
    background-color: var(--green_04);
    color: var(--green_01);
    border-left: 7px solid var(--green_01);
}

.side_menu_systems-system-details-active {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: var(--green_04);
    color: var(--green_01);
    border-left: 7px solid var(--green_01);
}

.side_menu_systems-system-details-nameIcon {
    display: flex;
    align-items: center;
    gap: 10px;

}

.side_menu_systems-system-details-nameIcon-icon {
    display: flex;
    justify-content: center;
    width: 60px;
    /* margin-left: 18px; */
    margin-right: 10px;
    /* background-color: yellowgreen */
}

.side_menu_systems-system-details-active .side_menu_systems-system-details-nameIcon-icon {
    display: flex;
    color: var(--black);
}

.side_menu_systems-system-details-nameIcon-name {
    display: flex;
    /* font-size: 15px; */
}

.side_menu_systems-system-details-active .side_menu_systems-system-details-nameIcon-name {
    display: flex;
    color: var(--black);
}

.side_menu_systems-system-details-arrow {
    display: flex;
    align-items: center;
}

.side_menu_systems-system-details-active .side_menu_systems-system-details-arrow {
    display: flex;
    align-items: center;
    color: var(--green_01);
}

.side_menu_systems-system-features {
    /* width: 364px; */
    /* height: 0;
    opacity: 0;
    overflow: hidden; */
    display: none;
    transition: height 0.3s ease-in;
}

.side_menu_systems-system-features-feature {
    margin-left: 40px;
    /* background-color: aqua; */
    border-left: 2px solid var(--green_04);
}




/* features expand status */
.side_menu_systems-system-features.expand {
    display: inline;
    height: auto;
    /* opacity: 1; */
    background-color: yellowgreen;
}





/* collapse status */
.side_menu.collapse .side_menu_systems-system-details {
    width: 83px;
    background-color: aqua;
}

.side_menu_systems-system-details-nameIcon-name-hide {
    display: none;
}

.side_menu.collapse .side_menu_systems-system-details-arrow {
    display: none;
}