.side_menu_system_sub_features {
    /* margin-left: 20px; */
}

.side_menu_system_sub_features li {
    list-style: none;
}

.side_menu_system_sub_features-details {
    padding: 7px 30px 7px 20px;
    /* margin: 5px 0 ; */
    color: var(--inactiveColor);
    /* background-color: aquamarine; */
}

.side_menu_system_sub_features-details:hover,
.side_menu_system_sub_features-details.active {
    background-color: var(--green_04);
    color: var(--green_01);
    border-left: 7px solid var(--green_01);
}

.side_menu_system_sub_features-details-active {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--green_04);
    color: var(--green_01);
    border-left: 7px solid var(--greeen_01);
    padding: 0;
    margin: 5px 0;
    /* width: auto; */
}