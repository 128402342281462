.side_menu {
    width: 90px;
    height: 100vh;
    background-color: var(--white);
    transition: width 0.3s ease-in;
    padding: 23px 0 30px 0;
    overflow-y: auto;
    scrollbar-color: var(--green_01) var(--green_04);
    scrollbar-width: thin;

    &&:hover {
        width: 400px;

        .side_menu-header {
            width: 100%;
            display: flex;
            opacity: 1;
            justify-content: center;
            transition: opacity 0.3s ease-in;
        }

        .side_menu-header_logo {
            width: 20%;
            transition: 0.3s ease-in;
            
        }





        .side_menu-user {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 10px;
        }

        .side_menu-user-img-div {}

        .side_menu-user-img {
            width: 60px;
            height: auto;
            border: 2px solid #BEC6C5;
            border-radius: 50%;
            transition: 0.3s ease-in;
        }



        .side_menu-user-info {
            display: flex;
            flex-direction: column;
            /* background-color: aqua; */
            width: 80%;


        }

        .side_menu-user-info-name {
            margin: 0;
            padding: 0;
            font-size: 25px;
            font-weight: bold;
            width: 200px;
        }

        .side_menu-user-info-designation {
            margin: 0;
            padding: 0;
            font-size: 18px;
            transform: translateY(-5px);
        }



        .side_menu-systems {
            margin: 50px 0 0 0;
            width: auto;
            transition: 0.3s ease-in;
        }







    }
}

.side_menu-header {
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in;
}

.side_menu-header_logo {
    width: 80%;
    transition: 0.3s ease-in;
}



.side_menu-user {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.side_menu-user-img-div {}

.side_menu-user-img {
    width: 50px;
    height: auto;
    border: 2px solid #BEC6C5;
    border-radius: 50%;
    transition: 0.3s ease-in;
}

.side_menu-user-info {
    display: none;
}


.side_menu-systems {
    width: 83px;
    margin-top: 68px;
    ;
}